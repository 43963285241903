<i18n>
{
  "en": {
    "actress_header": "",
    "people": ""
  },
  "ja": {
    "actress_header": "で始まる女のコ",
    "people": "人"
  }
}
</i18n>

<template>
<div class="section-wide" v-if="actressList.length">
  <div v-if="locale == 'ja'" class="heading">
    <h2>「{{ characterSub }}」{{ $t('actress_header') }} <span class="search-count">({{ actressList.length }}{{ $t('people') }})</span></h2>
  </div>
  <div class="actress">
    <div class="flex-grid">
      <div class="grid-item" v-for="(item, index) in actressList" :key="item.id">
        <router-link :to="`/search/?a=${item.id}`" class="entry">
          <div class="entry-media">
            <div class="media-thum">
              <!-- 100 x 100 would be preferred, but using 80x80 because the 50x50 legacy images are potato when upscaled -->
              <img :src="`/dyn/dla/images/actresses/portraits/${item.id}/1pon__@200.jpg`" @error="imgPlaceholder(item.image_url, item.id, index)" ref="actressImg" class="media-thum-image" loading="lazy" width="80" height="80" v-sfw />
            </div>
          </div>
          <div class="entry-meta">
            <div class="meta-name">{{ item.name }}</div>
          </div>
        </router-link>
      </div>
    </div>
  </div>
</div>
</template>

<script>
/* eslint max-len: 0 */
import EventBus from '@/assets/js/utils/EventBus';
import BifrostAPI from '@/assets/js/services/Bifrost/API';

export default {
  props: {
    character: {
      type: String,
    },
    characterSub: {
      type: String,
    },
  },

  data() {
    return {
      actressList: [],
      triedLegacyImg: {},
    };
  },

  async created() {
    const bfAPI = new BifrostAPI();
    let actresses = [];

    // set listener for emit event from the parent tab component
    EventBus.$on('actress:tab:active', async (title) => {
      // get list if the title matches the 1st character and hasn't been fetched yet
      if (title.substr(0, 1) === this.character && this.actressList.length === 0) {
        if (this.locale === 'ja') {
          actresses = await bfAPI.getActressList();
        } else if (this.locale === 'en') {
          actresses = await bfAPI.getActressEnList();
        }
        if (Object.prototype.hasOwnProperty.call(actresses[this.character], this.characterSub)) {
          this.actressList = actresses[this.character][this.characterSub];
        }
      }
    });
  },

  methods: {
    imgPlaceholder(url, actressID, index) {
      // first we try loading the actress image from DL admin. if that fails, we load the legacy
      // thumbnails. if that fails, use a static placeholder image
      if (this.triedLegacyImg[actressID]) {
        this.$refs.actressImg[index].src = '/img/common/actress_placeholder.png';
      } else {
        this.$refs.actressImg[index].src = url;
        this.$set(this.triedLegacyImg, actressID, true);
      }
    },
  },

  computed: {
    locale() {
      return this.$i18n.locale;
    },
  },
};
</script>

<style lang="scss" scoped>
</style>
